import { Component, OnInit, Input, HostListener } from '@angular/core';

import { Car } from '../car.model';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrls: ['./car-item.component.scss']
})
export class CarItemComponent implements OnInit {
  @Input() car: Car;
  carImgSrc: string;
  detailsBlockShown = false;
  screenWidth = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.setCarImageSrc();
  }

  constructor() { }

  ngOnInit() {
    this.setCarImageSrc();
  }

   private setCarImageSrc() {
    if (this.screenWidth <= 570) {
      this.carImgSrc = this.car.imgUrlMobile;
    } else if (this.screenWidth > 570 && this.screenWidth <= 768) {
      this.carImgSrc = this.car.imgUrlTablet;
    } else {
      this.carImgSrc = this.car.imgUrlDesktop;
    }
  }

}
