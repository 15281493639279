import { Car } from './car.model';

export const carsArray: Car[] = [

  {
    id: 'rs3-limousine',
    name: 'RS 3 Limousine',
    imgUrlDesktop: './assets/images/cars/1920/RS3_LM_1920x600_v3 clean.jpg',
    imgUrlTablet: './assets/images/cars/768/RS3_LM_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS3_LM_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a3/rs-3-limousine.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '400',
        unit: 'KM',
        description: '(294 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,8',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '500',
        unit: 'Nm',
        description: ''
      }
    ]
  },
  {
    id: 'rs3-sportback',
    name: 'RS 3 Sportback',
    imgUrlDesktop: './assets/images/cars/1920/RS3_SB_1920x600_v2 clean.jpg',
    imgUrlTablet: './assets/images/cars/768/RS3_SB_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS3_SB_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a3/rs-3-sportback.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '400',
        unit: 'KM',
        description: '(294 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,8',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '500',
        unit: 'Nm',
        description: ''
      }
    ]
  },
  {
    id: 'rs4-avant',
    name: 'RS 4 Avant',
    imgUrlDesktop: './assets/images/cars/1920/RS4_avant.jpg',
    imgUrlTablet: './assets/images/cars/768/RS4_Avant_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS4_Avant_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a4/rs-4-avant.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-black',
      firstButtonClass: 'btn-black',
      secondMoreButtonClass: 'btn-ghost-black'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '450',
        unit: 'KM',
        description: '(331 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '4,1',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '600',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rs5-coupe',
    name: 'RS 5 Coupé',
    imgUrlDesktop: './assets/images/cars/1920/RS5_coupe.jpg',
    imgUrlTablet: './assets/images/cars/768/RS5_Coupe_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS5_Coupe_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a5/rs-5-coupe.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '450',
        unit: 'KM',
        description: '(331 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,9',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '600',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rs5-sb',
    name: 'RS 5 Sportback',
    imgUrlDesktop: './assets/images/cars/1920/RS5_sportback.jpg',
    imgUrlTablet: './assets/images/cars/768/RS5_SB_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS5_SB_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a5/rs-5-sportback.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '450',
        unit: 'KM',
        description: '(331 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,9',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '600',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rs6-avant',
    name: 'RS 6 Avant',
    imgUrlDesktop: './assets/images/cars/1920//RS6_avant.jpg',
    imgUrlTablet: './assets/images/cars/768/RS6_Avant_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS6_Avant_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a6/rs-6-avant.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-black',
      firstButtonClass: 'btn-black',
      secondMoreButtonClass: 'btn-ghost-black'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '600',
        unit: 'KM',
        description: '(441 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,6',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Prędkość maks. (opcjonalna)',
        value: '305',
        unit: 'km/h',
        description: '250 km/h (standardowa)'
      }
    ]
  },
  {
    id: 'rs7-sb',
    name: 'RS 7 Sportback',
    imgUrlDesktop: './assets/images/cars/1920/RS7_sportback.jpg',
    imgUrlTablet: './assets/images/cars/768/RS7_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS7_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a7/rs-7-sportback.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '600',
        unit: 'KM',
        description: '(441 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,6',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Prędkość maks. (opcjonalna)',
        value: '305',
        unit: 'km/h',
        description: '250 km/h (standardowa)'
      }
    ]
  },
  {
    id: 's8',
    name: 'S8 ',
    imgUrlDesktop: './assets/images/cars/1920/S8.jpg',
    imgUrlTablet: './assets/images/cars/768/S8_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/S8_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/a8/s8.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '571',
        unit: 'KM',
        description: '(420 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,8',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '800',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rsq3',
    name: 'RS Q3',
    imgUrlDesktop: './assets/images/cars/1920/RSQ3.jpg',
    imgUrlTablet: './assets/images/cars/768/RS-Q3_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS-Q3_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/q3/rs-q3.html',
    carInfoBlockStyles: {
      infoPosition: 'bottom',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '400',
        unit: 'KM',
        description: '(294 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '4,5',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '480',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rsq3-sb',
    name: 'RS Q3 Sportback',
    imgUrlDesktop: './assets/images/cars/1920/RSQ3_sportback.jpg',
    imgUrlTablet: './assets/images/cars/768/RS-Q3-Sportback_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS-Q3-Sportback_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/q3/rs-q3-sportback.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '400',
        unit: 'KM',
        description: '(294 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '4,5',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '480',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'sq7',
    name: 'SQ7',
    imgUrlDesktop: './assets/images/cars/1920/SQ7.jpg',
    imgUrlTablet: './assets/images/cars/768/SQ7_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/SQ7_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/q7/sq7.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '507',
        unit: 'KM',
        description: '(373 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '4,1',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '770',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'sq8',
    name: 'SQ8',
    imgUrlDesktop: './assets/images/cars/1920/SQ8_2.jpg',
    imgUrlTablet: './assets/images/cars/768/SQ8_768x432_2.jpg',
    imgUrlMobile: './assets/images/cars/570/SQ8_570x320_2.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/q8/sq8.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '507',
        unit: 'KM',
        description: '(373 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '4,1',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '770',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'rsq8',
    name: 'RS Q8',
    imgUrlDesktop: './assets/images/cars/1920/RS_Q8_1920x600.jpg',
    imgUrlTablet: './assets/images/cars/768/RS_Q8_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/RS_Q8_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/q8/rs-q8.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '600',
        unit: 'KM',
        description: '(441 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,8',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Moment obrotowy',
        value: '800',
        unit: 'Nm',
        description: 'Maksymalny'
      }
    ]
  },
  {
    id: 'r8-coupe',
    name: 'R8 Coupé',
    imgUrlDesktop: './assets/images/cars/1920/R8_C_1920x600_CLEAR.jpg',
    imgUrlTablet: './assets/images/cars/768/R8_C_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/R8_C_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/r8/r8-coupe-v10-performance-rwd.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-white',
      firstButtonClass: 'btn-white',
      secondMoreButtonClass: 'btn-ghost-white'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '570',
        unit: 'KM',
        description: '(419 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,7',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Prędkość',
        value: '329',
        unit: 'km/h',
        description: 'Maksymalna'
      }
    ]
  },
  {
    id: 'r8-spyder',
    name: 'R8 Spyder',
    imgUrlDesktop: './assets/images/cars/1920/R8_S_1920x600_CLEAR.jpg',
    imgUrlTablet: './assets/images/cars/768/R8_S_768x432.jpg',
    imgUrlMobile: './assets/images/cars/570/R8_S_570x320.jpg',
    pageUrl: 'https://www.audi.pl/pl/web/pl/modele/r8/r8-spyder-v10-performance-quattro.html',
    carInfoBlockStyles: {
      infoPosition: 'top',
      carNameClass: 'font-black',
      firstButtonClass: 'btn-black',
      secondMoreButtonClass: 'btn-ghost-black'
    },
    techParameters: [
      {
        name: 'Moc',
        value: '620',
        unit: 'KM',
        description: '(455 kW)'
      },
      {
        name: 'Przyspieszenie',
        value: '3,2',
        unit: 's',
        description: '0 - 100 km/h'
      },
      {
        name: 'Prędkość',
        value: '329',
        unit: 'km/h',
        description: 'Maksymalna'
      }
    ]
  }
];
