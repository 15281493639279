import { Component, OnInit } from '@angular/core';

import { carsArray } from './cars';

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
  styleUrls: ['./cars-list.component.scss']
})
export class CarsListComponent implements OnInit {
  cars = carsArray;

  constructor() { }

  ngOnInit() {
  }

}
